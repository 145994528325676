/* Supprime les styles liés au mode sombre ou clair */

/* Styles généraux */
.settings-container {
    padding: 20px;
  }
  
  .password-section {
    margin-bottom: 20px;
  }
  
  /* Boutons */
  button {
    padding: 10px 20px;
    margin-top: 10px;
    cursor: pointer;
    background-color: #007bff; /* Couleur des boutons */
    color: #fff; /* Texte blanc pour les boutons */
    border: none;
    border-radius: 4px;
  }
  
  button:hover {
    background-color: #0056b3; /* Couleur des boutons au survol */
  }
  
  /* Inputs (champs de texte) */
  input {
    padding: 10px;
    margin-bottom: 10px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff; /* Fond des champs en blanc */
    color: #000; /* Texte noir pour les champs de texte */
  }
  