.profile-container {
  background-color: #f9f9f9;
  padding: 20px;
  min-height: 100vh;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
}

.profile-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 15px;
}

.profile-picture {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #eee;
}

.profile-info {
  flex: 1;
}

.profile-info h2 {
  margin: 0;
  font-size: 24px;
}

.profile-info p {
  margin: 5px 0;
  color: #666;
}

.profile-info button {
  background-color: #000;
  color: white;
  border: none;
  padding: 8px 16px;
  margin-top: 10px;
  border-radius: 25px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.profile-info button:hover {
  background-color: #222;
}

textarea {
  width: 100%;
  min-height: 80px;
  border-radius: 10px;
  border: 1px solid #ccc;
  padding: 10px;
  font-size: 14px;
  margin-top: 10px;
}

.profile-bio button {
  background-color: #000;
  color: white;
  border: none;
  padding: 8px 20px;
  margin-top: 10px;
  border-radius: 25px;
  cursor: pointer;
}

.actions-section {
  margin: 20px 0;
  display: flex;
  gap: 10px;
}

.action-button {
  background-color: #000;
  color: white;
  border: none;
  padding: 10px 16px;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.action-button:hover {
  background-color: #222;
}

.profile-tabs {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 20px 0;
}

.tab-button {
  background-color: transparent;
  border: none;
  font-size: 16px;
  color: #666;
  padding: 10px 15px;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  transition: all 0.3s ease;
}

.tab-button.active {
  color: #000;
  border-color: #000;
  font-weight: bold;
}

.profile-post {
  background-color: white;
  border-radius: 15px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.05);
}

.profile-post img,
.profile-post video {
  max-width: 100%;
  border-radius: 10px;
  margin-bottom: 10px;
}

.post-actions {
  display: flex;
  gap: 15px;
  margin-top: 10px;
}

.post-actions button {
  background-color: #000;
  color: white;
  border: none;
  padding: 6px 12px;
  border-radius: 25px;
  font-size: 14px;
  cursor: pointer;
}

.comments-section {
  margin-top: 10px;
  border-top: 1px solid #eee;
  padding-top: 10px;
}

.comment {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 8px;
}

.profile-picture-comment {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  object-fit: cover;
}

.add-comment-section {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.add-comment-section input {
  flex: 1;
  padding: 8px;
  border-radius: 20px;
  border: 1px solid #ccc;
}

.add-comment-section button {
  background-color: #000;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 25px;
  cursor: pointer;
}

.bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  border-top: 1px solid #ddd;
  display: flex;
  justify-content: space-around;
  padding: 12px 0;
  z-index: 999;
  transition: transform 0.3s ease;
}

.bottom-nav.hidden {
  transform: translateY(100%);
}

.bottom-nav.visible {
  transform: translateY(0);
}

.bottom-nav svg {
  font-size: 20px;
  cursor: pointer;
  color: #555;
}
