.bottom-nav {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-around;
  background-color: #fff;
  padding: 10px 0;
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

.bottom-nav button {
  background: none;
  border: none;
  font-size: 24px;
  color: #333;
  cursor: pointer;
}

.bottom-nav button:focus {
  outline: none;
}
